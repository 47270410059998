import { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Answer, Question } from "../Questionnaire";
import BreakpointUtils from "../../../utils/BreakpointUtils";


export default function MultipleChoice(props: { question: Question, setAnswer: (answer: Answer) => void, savedAnswer: Answer }) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const [checkboxesState, setCheckboxesState] = useState<boolean[]>(
    props.question.question_choices?.map(() => false) || []
  );
  const [customText, setCustomText] = useState<string>("");

  const getSelectedChoices = (checkboxesState: boolean[]) =>
    props.question.question_choices?.filter((_, index) => checkboxesState[index]) || [];

  const handleCheckboxChange = (index: number) => {
    const updatedState = [...checkboxesState];
    updatedState[index] = !updatedState[index];
    setCheckboxesState(updatedState);

    props.setAnswer({
      question_id: props.question.question_id,
      question_type: props.question.question_type,
      answers: getSelectedChoices(updatedState),
      customText: customText,
    });
  };

  const handleCustomTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomText(event.target.value);

    props.setAnswer({
      question_id: props.question.question_id,
      question_type: props.question.question_type,
      answers: getSelectedChoices(checkboxesState),
      customText: event.target.value,
    });
  };

  useEffect(() => {
    // restore checkboxes
    setCheckboxesState(props.question.question_choices?.map((c) => props.savedAnswer?.answers?.indexOf(c) > -1));
    setCustomText(props.savedAnswer?.customText);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%', marginTop: onMobile ? '10px' : 0 }}>
      <Typography variant='subtitle1' sx={{ fontSize: '1.1rem', lineHeight: '1.2', }}>
        {props.question.question_text}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        {/* checkboxes go below */}
        {props.question.question_choices?.map((choice, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={checkboxesState[index]}
                onChange={() => handleCheckboxChange(index)}
                color="secondary"
                size="small"
              />
            }
            label={choice}
            sx={{ mb: '-8px' }}
          />
        ))}

        {/* check if props.question.has_custom_text_field is true, then add a custom textbox below as well, disabled controlled by a checkbox */}
        {props.question.has_custom_text_field && (
          <TextField
            value={customText}
            onChange={handleCustomTextChange}
            color="secondary"
            placeholder="Other"
            variant="standard"
            size="small"
            InputProps={{
              sx: { height: 24, mt: '16px' },
            }}
          />
        )}
      </Box>

    </Box>
  );
};
