import { ComponentType, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/header/Header';
import SearchPage from '../pages/SearchPage';
import TrendingPage from '../pages/TrendingPage';
import InteractionsPage from '../pages/InteractionsPage';
import BookmarksPage from '../pages/BookmarkedPage';
import NotFoundPage from '../pages/NotFoundPage';
import AboutPage from '../pages/AboutPage';
import ChangelogPage from '../pages/ChangelogPage';
import BootstrappingPage from '../pages/BootstrappingPage';
import IterativeTrainingPage from '../pages/IterativeTrainingPage';
import FeatureWordsPage from '../pages/FeatureWordsPage';
import OtherToolsPage from '../pages/OtherToolsPage';
import VotesVisualisationPage from '../pages/VotesVisualisationPage';
import DashboardSettingsPage from '../pages/settings/DashboardSettingsPage';
import ClassifierSettingsPage from '../pages/settings/ClassifierSettingsPage';
import FeatureToggleSettingsPage from '../pages/settings/FeatureToggleSettingsPage';
import GeneralSettingsPage from '../pages/settings/GeneralSettingsPage';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import InvitationLinksPage from '../pages/onboarding/InvitationLinksPage';
import PrivacyPolicy from '../pages/legal/PrivacyPolicy';
import { Box } from '@mui/material';
import TermsOfService from '../pages/legal/TermsOfService';
import Imprint from '../pages/legal/Imprint';
import MaintenancePage from '../pages/MaintenancePage';
import LandingPage from '../pages/LandingPage';
import CollectionsPage from '../pages/CollectionsPage';
import AuthorPage from '../pages/AuthorPage';
const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage'));
const AccountRecoveryPage = lazy(() => import('../pages/AccountRecoveryPage'));
const HighlightsInferenceSettingsPage = lazy(() => import('../pages/settings/HighlightsInfereneSettingsPage'));
const ProceedingsUploaderPage = lazy(() => import('../pages/settings/ProceedingsUploaderPage'));
const UserStatisticsPage = lazy(() => import('../pages/settings/UserStatisticsPage'));
const UsersSettingsPage = lazy(() => import('../pages/settings/UsersSettingsPage'));
const MaintenanceControlPage = lazy(() => import('../pages/settings/MaintenanceControlPage'));
const LoggingViewPage = lazy(() => import('../pages/LoggingViewPage'));
const SemanticSearchPage = lazy(() => import('../pages/SemanticSearchPage'));
const ConferencePage = lazy(() => import('../pages/conferences/ConferencePage'));
const ConferenceSettingsPage = lazy(() => import('../pages/conferences/ConferenceSettingsPage'));
const ConferencePlannerSettings = lazy(() => import('../pages/settings/ConferencePlannerSettings'));
const OnboardingSettingsPage = lazy(() => import('../pages/settings/OnboardingSettingsPage'));
const InactiveUsersSettingsPage = lazy(() => import('../pages/settings/InactiveUsersSettingsPage'));
const TestResultsPage = lazy(() => import('../pages/settings/TestResultsPage'));
const WikiPage = lazy(() => import('../pages/settings/WikiPage'));
const FailedPapersPage = lazy(() => import('../pages/settings/FailedPapersPage'));
const LogsPage = lazy(() => import('../pages/settings/LogsPage'));
const StatisticsPage = lazy(() => import('../pages/settings/StatisticsPage'));
const ConferencePapersPage = lazy(() => import('../pages/ConferencePapersPage'));
const ConferenceExplorerPage = lazy(() => import('../pages/ConferenceExplorerPage'));
const NotifyUsersPage = lazy(() => import('../pages/settings/NotifyUsersPage'));
const RegistrationPage = lazy(() => import('../pages/onboarding/RegistrationPage'));
const TutorialsPage = lazy(() => import('../pages/TutorialsPage'));

const withLayout = (WrappedPage: ComponentType<{}>) => () =>
(
  <>
    <Box sx={{ minHeight: 'calc(100vh - 2em)' }}>
      <Header />
      <WrappedPage />
    </Box>
    <Footer />
  </>
);

export default function ScholarInboxApp() {

  return (
    <Routes>
      {/* authentication */}
      <Route path="/login/:key" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/account_recovery" element={
        <Suspense fallback={<>...</>}>
          <AccountRecoveryPage />
        </Suspense>
      } />
      <Route path="/reset_password/:key" element={
        <Suspense fallback={<>...</>}>
          <ResetPasswordPage />
        </Suspense>
      } />
      <Route path="/reset_password" element={
        <Suspense fallback={<>...</>}>
          <ResetPasswordPage />
        </Suspense>
      } />

      {/* conferences */}
      <Route path="/conference/:conference_short_name/:conference_year/settings" element={
        <Suspense fallback={<>...</>}>
          <ConferenceSettingsPage />
        </Suspense>
      } />
      <Route path="/conference/:conference_short_name/:conference_year" element={
        <Suspense fallback={<>...</>}>
          <ConferencePage />
        </Suspense>
      } />

      {/* onboarding */}
      <Route path="/onboarding/invites/:key" element={<InvitationLinksPage />} />
      <Route path="/onboarding/registration" element={
        <Suspense fallback={<>...</>}>
          <RegistrationPage />
        </Suspense>
      } />
      <Route path="/onboarding/registration/:key" element={
        <Suspense fallback={<>...</>}>
          <RegistrationPage />
        </Suspense>
      } />

      {/* header pages */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/search" element={withLayout(SearchPage)()} />
      <Route path="/trending" element={withLayout(TrendingPage)()} />
      <Route path="/interactions" element={withLayout(InteractionsPage)()} />
      <Route path="/bookmarks" element={withLayout(BookmarksPage)()} />
      <Route path="/author/:author_id" element={withLayout(AuthorPage)()} />

      <Route path="/collections" element={
        <Suspense fallback={<>...</>}>
          {withLayout(CollectionsPage)()}</Suspense>} />
      <Route path="/semantic-search" element={
        <Suspense fallback={<>...</>}>
          {withLayout(SemanticSearchPage)()}
        </Suspense>
      } />
      <Route path="/logging-view" element={
        <Suspense fallback={<>...</>}>
          {withLayout(LoggingViewPage)()}
        </Suspense>
      } />
      <Route path="/conference-explorer" element={
        <Suspense fallback={<>...</>}>
          {withLayout(ConferenceExplorerPage)()}
        </Suspense>
      } />
      <Route path="/conference-papers" element={
        <Suspense fallback={<>...</>}>
          {withLayout(ConferencePapersPage)()}
        </Suspense>
      } />


      {/* user menu pages */}
      <Route path="/about" element={withLayout(AboutPage)()} />
      <Route path="/bootstrapping" element={withLayout(BootstrappingPage)()} />
      <Route path="/iterative-training" element={withLayout(IterativeTrainingPage)()} />
      <Route path="/feature-words" element={withLayout(FeatureWordsPage)()} />
      <Route path="/changelog" element={withLayout(ChangelogPage)()} />
      <Route path="/other-tools" element={withLayout(OtherToolsPage)()} />
      <Route path="/tsne" element={withLayout(VotesVisualisationPage)()} />
      <Route path="/settings" element={withLayout(DashboardSettingsPage)()} />

      <Route path="/tutorials" element={
        <Suspense fallback={<>...</>}>
          {withLayout(TutorialsPage)()}
        </Suspense>
      } />

      {/* settings subpages */}
      <Route path="/settings/classifier" element={withLayout(ClassifierSettingsPage)()} />
      <Route path="/settings/feature-toggle" element={withLayout(FeatureToggleSettingsPage)()} />
      <Route path="/settings/general" element={withLayout(GeneralSettingsPage)()} />
      <Route path="/settings/inactive-users" element={
        <Suspense fallback={<>...</>}>
          {withLayout(InactiveUsersSettingsPage)()}
        </Suspense>
      } />
      <Route path="/settings/onboarding" element={
        <Suspense fallback={<>...</>}>
          {withLayout(OnboardingSettingsPage)()}
        </Suspense>
      } />
      <Route path="/settings/users" element={
        <Suspense fallback={<>...</>}>
          {withLayout(UsersSettingsPage)()}
        </Suspense>
      } />
      <Route path="/settings/conference-planner" element={
        <Suspense fallback={<>...</>}>
          {withLayout(ConferencePlannerSettings)()}
        </Suspense>
      } />
      <Route path="/settings/statistics" element={
        <Suspense fallback={<>...</>}>
          {withLayout(StatisticsPage)()}
        </Suspense>
      } />
      <Route path="/settings/logs" element={
        <Suspense fallback={<>...</>}>
          {withLayout(LogsPage)()}
        </Suspense>
      } />
      <Route path="/settings/failed" element={
        <Suspense fallback={<>...</>}>
          {withLayout(FailedPapersPage)()}
        </Suspense>
      } />
      <Route path="/settings/proceedings-uploader" element={
        <Suspense fallback={<>...</>}>
          {withLayout(ProceedingsUploaderPage)()}
        </Suspense>
      } />
      <Route path="/settings/highlights-inference-settings" element={
        <Suspense fallback={<>...</>}>
          {withLayout(HighlightsInferenceSettingsPage)()}
        </Suspense>
      } />
      <Route path="/settings/wiki" element={
        <Suspense fallback={<>...</>}>
          {withLayout(WikiPage)()}
        </Suspense>
      } />
      <Route path="/settings/testresults" element={
        <Suspense fallback={<>...</>}>
          {withLayout(TestResultsPage)()}
        </Suspense>
      } />
      <Route path="/settings/maintenance-control" element={
        <Suspense fallback={<>...</>}>
          {withLayout(MaintenanceControlPage)()}
        </Suspense>
      } />
      <Route path="/settings/notify-users" element={
        <Suspense fallback={<>...</>}>
          {withLayout(NotifyUsersPage)()}
        </Suspense>
      } />
      <Route path="/settings/user-statistics" element={
        <Suspense fallback={<>...</>}>
          {withLayout(UserStatisticsPage)()}
        </Suspense>
      } />
      <Route path="/unsubscribe_newsletter/:key" element={
        <Suspense fallback={<>...</>}>
          <GeneralSettingsPage />
        </Suspense>
      } />
      <Route path="/unsubscribe_digest/:key" element={
        <Suspense fallback={<>...</>}>
          <GeneralSettingsPage />
        </Suspense>
      } />


      {/* legal pages */}
      {/* We want our legal policies visible to the outside, so public routes for those */}
      <Route path="/public/privacy-policy" element={<><PrivacyPolicy /><Footer publicLegalPages={true} /></>} />
      <Route path="/public/terms-of-service" element={<><TermsOfService /><Footer publicLegalPages={true} /></>} />
      <Route path="/public/imprint" element={<><Imprint /><Footer publicLegalPages={true} /></>} />
      {/* When a user is logged in, they should be seeing also the header (menu bar) */}
      <Route path="/privacy-policy" element={withLayout(PrivacyPolicy)()} />
      <Route path="/terms-of-service" element={withLayout(TermsOfService)()} />
      <Route path="/imprint" element={withLayout(Imprint)()} />

      {/* void */}
      <Route path="/search" element={withLayout(SearchPage)()} />
      <Route path="*" element={withLayout(NotFoundPage)()} />
      <Route path="/maintenance" element={MaintenancePage()} />
    </Routes>
  );
}