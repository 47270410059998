import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel, Link,
  TextField,
  Typography
} from '@mui/material';
import { SendLoginData } from '../services/RegistrationService';
import { useEffect, useState } from 'react';
import { GetLandingPageInfo, RegisterSignUpInterest } from '../services/RegistrationService';
import BoxSection from '../components/box/BoxSection';
import BreakpointUtils from '../utils/BreakpointUtils';
import Logo from '../components/Logo';
import Footer from "../components/Footer";
import theme from "../theme";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { WelcomeMessage } from "../components/WelcomeMessage";
import SponsorSegment from '../components/SponsorSegment';
import { GoogleLogin } from '@react-oauth/google';
import { GetMaintenanceData, VerifyGoogleCredential } from '../services/AuthService';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
// load google fonts
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

const reviews = [
  {
    image: '/image/landing-page/01.png'
  },
  {
    image: '/image/landing-page/02.png'
  },
  {
    image: '/image/landing-page/03.png'
  },
  {
    image: '/image/landing-page/04.png'
  },
  {
    image: '/image/landing-page/05.png'
  },
  {
    image: '/image/landing-page/06.png'
  },
  {
    image: '/image/landing-page/07.png'
  },
  {
    image: '/image/landing-page/08.png'
  },
  {
    image: '/image/landing-page/09.png'
  },
  {
    image: '/image/landing-page/10.png'
  },
  {
    image: '/image/landing-page/11.png'
  },
  {
    image: '/image/landing-page/12.png'
  },
  {
    image: '/image/landing-page/13.png'
  },
  {
    image: '/image/landing-page/14.png'
  },
  {
    image: '/image/landing-page/15.png'
  },
  {
    image: '/image/landing-page/16.png'
  },
  {
    image: '/image/landing-page/17.png'
  },
  {
    image: '/image/landing-page/18.png'
  },
  {
    image: '/image/landing-page/19.png'
  },
  {
    image: '/image/landing-page/20.png'
  },
  {
    image: '/image/landing-page/21.png'
  },
  {
    image: '/image/landing-page/22.png'
  },
  {
    image: '/image/landing-page/23.png'
  },
  {
    image: '/image/landing-page/24.png'
  },
]

const keyFeatures = [
  {
    icon: 'mark_email_unread',
    title: "You've got Mail",
    text: 'Receive daily or weekly email alerts with your latest paper recommendations',
    register: false,
  },
  {
    icon: 'thumb_up',
    title: 'Pick Favorites',
    text: 'Vote for papers to improve your personal AI-based paper suggestions',
    register: false,
  },
  {
    icon: 'trending_up',
    title: 'Watch Trends',
    text: 'Follow current research trends and find out which papers others read and like',
    register: false,
  },
  {
    icon: 'search',
    title: 'Find Anything',
    text: 'Find papers via semantic search or by searching for title, abstract and authors',
    register: false,
  },
  {
    icon: 'insert_chart',
    title: 'Skim Quickly',
    text: 'Skim papers quickly with the help of highlights and figure / table previews',
    register: false,
  },
  {
    icon: 'sprint',
    title: 'Access Instantly',
    text: 'Download paper PDFs in a matter of seconds via our fast pdf cache',
    register: false,
  },
  {
    icon: 'smartphone',
    title: 'Read Anywhere',
    text: 'Read your personal digest on PC or mobile and bookmark relevant papers for later',
    register: false,
  },
  {
    icon: 'input',
    title: 'Register Now',
    text: 'Join us now: Registration is fast and easy, and takes you only a few minutes',
    register: true,
  },
]

export default function LoggedOutPage(props: { setLoggedIn: (loggedIn: boolean) => void }) {
  const navigate = useNavigate();
  const onMobile = BreakpointUtils.lessThanMedium();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordEmptyError, setPasswordEmptyError] = useState<boolean>(false);
  const [emailEmptyError, setEmailEmptyError] = useState<boolean>(false);
  const [wrongCredentialsError, setWrongCredentialsError] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [registrationOpened, setRegistrationOpened] = useState<boolean>(true);
  const [interestEmail, setInterestEmail] = useState<string>('');
  const [waitlistEnabled, setWaitlistEnabled] = useState<boolean>(false);
  const [key, setKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const req = GetLandingPageInfo();
    const maintenanceStatus = GetMaintenanceData();
    maintenanceStatus.then(res => {
      // check if in maintenance mode
      if (!res.success || res.is_maintenance_mode) {
        navigate({
          pathname: '/maintenance',
        });
      }
      else {
      }
    });

    req.then(resp => {
      setRegistrationOpened(resp.registrationOpened);
      setWaitlistEnabled(resp.waitlistEnabled);
      setKey(resp.registrationKey);
      setLoading(false);
    });
  }, []);

  const SubmitLoginData = () => {
    // send login data to backend
    // verify that user exists and that password is correct
    SendLoginData(email, password).then((response) => {
      // // redirect to digest Page
      if (response.success) {
        props.setLoggedIn(true);
      }
      else {
        setWrongCredentialsError(true);
        props.setLoggedIn(false);
      }
    });
  };

  const registerSignUpInterest = () => {
    const req = RegisterSignUpInterest(interestEmail);
    req.then(res => {
      if (res.success) {
        setSnackbarOpen(true);
      }
    });
  };

  const handleForgotPassword = () => {
    // redirect to pre recovery page that asks for email address to send recovery link to
    navigate({
      pathname: '/account_recovery',
      search: '?e=' + email,
    });
  };

  const handleRegisterAreaClick = () => {
    navigate({
      pathname: '/onboarding/registration'
    });
  }

  const validatePassword = () => {
    setPasswordEmptyError(password.length === 0);
    return !(password.length === 0);
  };

  const validateEmail = () => {
    setEmailEmptyError(email.length === 0);
    return !(email.length === 0);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: async tokenResponse => {
      console.log(tokenResponse);
      const req = VerifyGoogleCredential(tokenResponse.access_token, key);
      req.then(res => {
        if (res.success) {
          navigate({
            pathname: '/login/' + res.key
          });
        } else {
          console.log(res);
        }
      });
    },
  });

  return (
    <>
      {!loading ? (
        <Box sx={{ minHeight: 'calc(100vh - 2em)' }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            width: '100vw',
            marginLeft: onMobile ? '-16px' : '-24px',
            padding: 0,
            backgroundColor: '#F2F2F2',
          }}>
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
              borderRadius: '8px',
              p: onMobile ? 2 : 5,
              mt: onMobile ? 2 : 8,
              backgroundColor: 'white',
              width: onMobile ? '90vw' : 'max-content',
              mx: 'auto',
            }}>

              <Box sx={{ mb: onMobile ? '5px' : '2em', width: onMobile ? '100%' : '800px' }}>
                <Logo />
              </Box>

              <Box style={{ width: onMobile ? '100%' : '800px' }}>
                <Typography
                  sx={{
                    mb: onMobile ? '' : '2rem',
                    textAlign: 'justify',
                  }}
                  variant={onMobile ? 'body2' : 'body1'}
                >
                  {WelcomeMessage}
                </Typography>
              </Box>

              <Box style={{ width: onMobile ? '100%' : '488px' }}>

                {wrongCredentialsError && (
                  <FormHelperText id="component-error-text" sx={{ color: 'red', marginBottom: '1em' }}>
                    Invalid Username or Password!
                  </FormHelperText>
                )}

                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <InputLabel color="secondary" htmlFor="email">Email</InputLabel>
                  <Input
                    id="email"
                    value={email}
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(event.target.value);
                    }}
                    onBlur={() => {
                      validateEmail();
                    }}
                    error={emailEmptyError}
                    color="secondary"
                    aria-describedby="component-error-text"
                  />
                  {emailEmptyError && (<FormHelperText id="component-error-text" sx={{ color: 'red' }}>Email field cannot be empty!</FormHelperText>)}
                </FormControl>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: 1 }}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel color='secondary' htmlFor="outlined-adornment-password" sx={{ ml: '-13px' }}>Password</InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type={passwordVisible ? 'text' : 'password'}
                      onChange={(event) => {
                        setWrongCredentialsError(false);
                        setPassword(event.target.value);
                      }}
                      onBlur={() => {
                        validatePassword();
                      }}
                      color="secondary"
                      aria-describedby="component-error-text"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {passwordEmptyError && (
                      <FormHelperText id="component-error-text" sx={{ color: 'red', marginLeft: '0px' }}>
                        Password field cannot be empty!
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Link component="button" onClick={handleForgotPassword} sx={{ marginBottom: '1em', color: 'grey', cursor: 'pointer' }}>
                    Forgot Password or Login Link?
                  </Link>
                </Box>

                <Button
                  color={wrongCredentialsError ? 'error' : 'secondary'}
                  fullWidth
                  variant="outlined"
                  onClick={() => SubmitLoginData()}
                  style={{ marginBottom: '1em' }}
                >
                  Login
                </Button>

                {registrationOpened ? (
                  <Button
                    color='secondary'
                    fullWidth
                    variant="outlined"
                    href={'/onboarding/registration'}
                    style={{ marginBottom: '1em' }}
                  >
                    Register
                  </Button>
                ) : (
                  <Alert severity="warning" sx={{ marginBottom: '1em' }}>
                    Registration is temporarily unavailable.
                  </Alert>
                )}

                {registrationOpened && (
                  <Button
                    color={wrongCredentialsError ? 'error' : 'secondary'}
                    fullWidth
                    variant="outlined"
                    onClick={() => handleGoogleSignIn()}
                  // style={{ marginBottom: '1em' }}
                  >
                    <img src='/image/google-logo-g-suite.png' height={'25px'} style={{ paddingRight: '5px' }} />
                    Continue With Google
                  </Button>
                )}

              </Box>

              {!registrationOpened && waitlistEnabled && (<>
                <Box style={{ width: onMobile ? '100%' : '800px' }}>
                  <Typography
                    sx={{
                      textAlign: 'justify',
                    }}
                    variant='body1'
                  >
                    Registration is temporarily unavailable.<br /> Enter your email below and we will email you with a priority registration link when available.
                  </Typography>
                </Box>
                <BoxSection sx={{ width: onMobile ? '100%' : '488px', pb: 0, }}>
                  <TextField
                    color='secondary'
                    fullWidth
                    id='email'
                    label='Email'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInterestEmail(event.target.value);
                    }}
                    value={interestEmail}
                    variant='standard'
                  />

                  <Button
                    color='secondary'
                    fullWidth
                    onClick={() => registerSignUpInterest()}
                    sx={{
                      margin: '0 auto',
                      mt: 1
                    }}
                    variant='outlined'
                  >
                    Join the wait list
                  </Button>
                </BoxSection>
              </>)
              }
            </Box>
            <Typography
              sx={{
                mx: 'auto',
                textAlign: 'justify',
                mt: 4,
                mb: 2,
              }}
              variant={onMobile ? 'h5' : 'h4'}
            >
              Key Features
            </Typography>
            <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 2, sm: 2, md: 16 }} sx={{
              maxWidth: '895px',
            }}>
              {keyFeatures.map((feature, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Box sx={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
                    borderRadius: '8px',
                    p: onMobile ? 1 : 2,
                    pt: 4,
                    mx: 'auto',
                    backgroundColor: 'white',
                    maxWidth: onMobile ? '90vw' : 'auto',
                    height: onMobile ? 'auto' : '200px',
                    cursor: registrationOpened && feature.register ? 'pointer' : 'auto',
                    '&:hover': {
                      // transform: registrationOpened && feature.register ? 'translateY(-0.25em)' : '',
                      // transitionDuration: '300ms',
                      backgroundColor: registrationOpened && feature.register ? theme.palette.grey[100] : '',
                    }
                  }}
                    onClick={() => registrationOpened && feature.register ? handleRegisterAreaClick() : {}}
                  >
                    <span className="material-symbols-outlined" style={{
                      transform: 'scale(3)',
                      marginBottom: 16,
                    }}>
                      {feature.icon ? feature.icon : ''}
                    </span>
                    <Typography
                      sx={{
                        my: 1
                      }}

                      variant={onMobile ? 'subtitle1' : 'h6'}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                      }}
                      variant='body2'
                    >
                      {feature.text}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Typography
              sx={{
                display: 'flex',
                // mx: 'auto',
                mt: 2,
                color: '#595959',
                maxWidth: '90vw',
              }}
              variant={'body2'}
            >
              More information at our blog: &nbsp;<a target='_blank' href='https://sites.google.com/view/avg-blog/scholar-inbox'>https://sites.google.com/view/avg-blog/scholar-inbox</a>
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                mx: 'auto',
                textAlign: 'justify',
                mt: 4,
                mb: 2,
              }}
              variant={onMobile ? 'h5' : 'h4'}
            >
              Preview
            </Typography>
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
              borderRadius: '8px',
              backgroundColor: 'white',
              width: onMobile ? '90vw' : 'max-content',
              mx: 'auto',
            }}>
              <img src={'/image/scholar-inbox-teaser.png'} width={onMobile ? '100%' : '880px'} alt={'Scholar Inbox Teaser'} style={{ borderRadius: '8px', }} />
            </Box>
            <Typography
              sx={{
                display: 'flex',
                mx: 'auto',
                textAlign: 'justify',
                mt: 4,
                mb: 2,
              }}
              variant={onMobile ? 'h5' : 'h4'}
            >
              What Users Say
            </Typography>
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
              borderRadius: '8px',
              backgroundColor: 'white',
              width: onMobile ? '90vw' : 'max-content',
              mx: 'auto',
            }}>
              <Box sx={{
                width: onMobile ? '100%' : '880px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                {reviews.map(review => (
                  <img src={review.image} key={review.image} style={{
                    marginBottom: 5,
                    maxWidth: onMobile ? '100%' : '650px',
                  }} />
                ))}
              </Box>
            </Box>
            <Typography
              sx={{
                display: 'flex',
                mx: 'auto',
                textAlign: 'justify',
                mt: 4,
                mb: 2,
              }}
              variant={onMobile ? 'h5' : 'h4'}
            >
              Our Sponsors
            </Typography>
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
              borderRadius: '8px',
              backgroundColor: 'white',
              width: onMobile ? '90vw' : 'max-content',
              maxWidth: '880px',
              mx: 'auto',
            }}>
              <Box sx={{
                width: onMobile ? '100%' : '880px',
                p: 2,
              }}>
                <SponsorSegment noheading />
              </Box>
            </Box>
            <Box sx={{ mb: 2 }}></Box>
            <Footer bgColour={'#F2F2F2'} publicLegalPages={true} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 10 }}>
          <TripleDotLoadingProgress />
        </Box>
      )}
    </>
  );
};
