import {
  Box,
  List, ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { GetClassifierPerformanceData } from '../../services/SettingsService';
import { GetSessionData } from '../../services/AuthService';
import dayjs, { Dayjs } from 'dayjs';
import BoxSettingsContent from '../../components/box/BoxSettingsContent';
import TripleDotLoadingProgress from '../../components/tools/TripleDotLoadingProgress';

interface Metric {
  name: string;
  training: number;
  validation: number;
}

interface Stats {
  key: string;
  metrics: Metric[];
}

export default function ClassifierSettingsPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [unvotedWeights, setUnvotedWeights] = useState(0.0);
  const [upvotedWeights, setUpvotedWeights] = useState(0.0);
  const [downvotedWeights, setDownvotedWeights] = useState(0.0);
  const [stats, setStats] = useState<Stats[]>([]);
  const [cases, setCases] = useState<string[]>([]);
  const [userID, setUserID] = useState(null);
  const [recommenderTimestamp, setRecommenderTimestamp] = useState<Dayjs>(dayjs());
  const [showPlots, setShowPlots] = useState<boolean>(false);

  const handleSettingsDataUpdate = (res) => {
    try {
      setUnvotedWeights(res.weights[2]); // w_u
      setUpvotedWeights(res.weights[0]); // w_p
      setDownvotedWeights(res.weights[1]); //w_n
      setRecommenderTimestamp(dayjs(res.recommender_timestamp, "MM-DD-YYYY"));
      setCases(res.cases);
      setStats(res.evaluation_values);
      setShowPlots(res.show_plots);
      setLoading(false);
    } catch (e) {
      if (e instanceof TypeError) {
        console.log('error fetching data: ' + e);
      }
    }
  };

  useEffect(() => {
    const req = GetSessionData();
    req.then(res => {
      setUserID(res.user_id);
    })
    const settingsData = GetClassifierPerformanceData();
    settingsData.then(res => {
      handleSettingsDataUpdate(res);
    });
  }, []);

  function insertPlot(plot_name, userID) {
    return <Box>
      <img
        style={{ maxWidth: '800px', width: '95%' }}
        src={"https://www.scholar-inbox.com:" + "/plots/precision_recall/" + plot_name + "_" + userID + "_" + recommenderTimestamp.format('DDMMYYYY') + ".svg"}
        alt={plot_name}
      />
    </Box>
  }
  return (
    <BoxSettingsContent sx={{ maxWidth: '700px' }}>
      {loading ? (
        <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 10 }}>
          <TripleDotLoadingProgress />
        </Box>) : (
        <>
          <Typography sx={{ mt: 2, mb: 2 }} >
            On this page you can find different information about your recommender:
            <List sx={{ listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item' }}> The recommender update date.</ListItem>
              <ListItem sx={{ display: 'list-item' }}> How your recommender weights different papers.</ListItem>
              <ListItem sx={{ display: 'list-item' }}> Different evaluation metric of your recommender. (Note that this becomes available only once you've accumulated more than 20 positively and 20 negatively rated papers.)</ListItem>
            </List>
            If you want to find more detailed information about the recommender, you can find it in our <a target='_blank'
              href='https://sites.google.com/view/avg-blog/scholar-inbox'>blog</a>.
          </Typography>
          <Typography variant='body1' sx={{ mt: 2, mb: 2 }}>
            <b>Your recommender was updated on {recommenderTimestamp.format('DD-MM-YYYY HH:mm:ss')} </b>
          </Typography>
          <Typography variant='body1' sx={{ mt: 2, mb: 2 }}>
            <b>Paper Weights: unvoted - {unvotedWeights}, upvoted - {upvotedWeights}, downvoted
              - {downvotedWeights}</b>
          </Typography>
          {showPlots ?
            (
              <>
                <Typography>
                  In the evaluation table below, you can find different evaluation metrics for your recommender. The table is split into two parts:
                  <List sx={{ listStyleType: 'disc' }}>
                    <ListItem sx={{ display: 'list-item' }}> <b>Total</b>. In this case papers from upvoted, downvoted and base categories are used for the evaluation</ListItem>
                    <ListItem sx={{ display: 'list-item' }}> <b>Upvoted vs Downvoted</b>. In this case only upvoted and downvoted papers are used for the evaluation</ListItem>
                  </List>
                </Typography>
                <Table sx={{ width: '600px' }} size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {cases.map((c) => (
                        <>
                          <TableCell colSpan={2} sx={{ alignContent: 'center', textAlign: 'center', }}>{c}</TableCell>
                        </>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Training</TableCell>
                      <TableCell>Validation</TableCell>
                      <TableCell>Training</TableCell>
                      <TableCell>Validation</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stats.map((row) => (
                      <TableRow key={row[0]}>
                        <TableCell>{row[0]}</TableCell>
                        <TableCell>{row[1]}</TableCell>
                        <TableCell>{row[2]}</TableCell>
                        <TableCell>{row[3]}</TableCell>
                        <TableCell>{row[4]}</TableCell>
                      </TableRow>))
                    }
                  </TableBody>
                </Table>
                <Box>
                  {insertPlot('total', userID)}
                  {insertPlot('upvoted_vs_downvoted', userID)}
                </Box>
              </>) : ''}
        </>)}
    </BoxSettingsContent>
  )
}
