import axiosInstance from "./axiosInstance";

export async function UnsubscribeNewsletter(sha_key:string) {
    try {
        const response = await axiosInstance.post('/unsubscribe_newsletter/' + sha_key);
        return await response.data;
    }
    catch (err) {
      console.log(err);
      return {};
    }
}

export async function UnsubscribeDigest(sha_key:string) {
    try {
        const response = await axiosInstance.post('/unsubscribe_digests/' + sha_key);
        return await response.data;
    }
    catch (err) {
        console.log(err);
        return {};
    }
}