import axiosInstance from "./axiosInstance";
import { UpdateCollectionTab } from "../components/CollectionTab";
import { Answer } from "../components/questionnaire/Questionnaire";

export async function GetDigestDateRangeData(digestDateFrom?: string, digestDateTo?: string) {
  try {
    const response = await axiosInstance.get('/' + (digestDateFrom && digestDateTo ? ('?from=') + digestDateFrom + ('&to=') + digestDateTo : ''));
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetPaperColourFromRelevance(relevance: number) {
  try {
    const response = await axiosInstance.get('/get-paper-colour', {
      params: {
        relevance: Math.round(relevance),
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SetUserSeenWelcomeTutorial() {
  try {
    const response = await axiosInstance.get('/set-seen-welcome-tutorial');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetDigestData(date?: string, paperID?: string, page?: number, digestDateFrom?: string, digestDateTo?: string) {
  try {
    const response = await axiosInstance.get('/', {
      params: {
        ...(date ? { date: date } : {}),
        ...(paperID ? { paper_id: paperID } : {}),
        ...(page ? { p: page } : {}),
        ...(digestDateFrom ? { from: digestDateFrom } : {}),
        ...(digestDateTo ? { to: digestDateTo } : {})
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetTrendingData(column: string = 'hype', category: string = 'ALL', ascending: string = '0', dates: string = '7', page?: number) {
  try {
    const response = await axiosInstance.get('/trending', {
      params: {
        ...(column ? { column: column } : {}),
        ...(category ? { category: category } : {}),
        ...(ascending ? { ascending: ascending } : {}),
        ...(dates ? { dates: dates } : {}),
        ...(page ? { p: page } : {})
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetTwitterData(paper_id: string = '397873') {
  try {
    const response = await axiosInstance.get('/tweets/' + paper_id); //
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetFailedData(date?: string, paperID?: string) {
  try {
    const response = await axiosInstance.get('/failed');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetProceedingsUploaderData(conf_year: string = '_0') {
  try {
    const response = await axiosInstance.get('/settings/proceedings-uploader?' + 'conf_year=' + conf_year);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function SetProceedingsUploading(conf_year: string) {
  try {
    const response = await axiosInstance.post('/upload_proceeding/',
      {
        conf_year: conf_year,
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error uploading proceedings');
    console.log(err);
    return {};
  }
}


export async function SetConfPapersPerDigestService(conf_papers_per_digest: number) {
  try {
    const response = await axiosInstance.post('/set_conf_papers_per_digest/',
      {
        conf_papers_per_digest: conf_papers_per_digest
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error setting conf papers per digest');
    console.log(err);
    return {};
  }
}


export async function GetBookmarkedData(page?: number) {
  try {
    const response = await axiosInstance.get('/bookmarks', {
      params: {
        ...(page ? { p: page } : {})
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function GetAuthorData(author_id: number, orderBy: string, page?: number) {
  try {
    const response = await axiosInstance.get('/author/' + author_id, {
      params: {
        ...(orderBy ? { orderBy: orderBy } : {}),
        ...(page ? { p: page } : {})
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function GetInteractionsData(sortBy: string = 'ranking_score', showFilter: string = 'all', orderBy: string = '0', page?: number) {
  try {
    const response = await axiosInstance.get('/interactions', {
      params: {
        ...(sortBy ? { column: sortBy } : {}),
        ...(showFilter ? { type: showFilter } : {}),
        ...(orderBy ? { ascending: orderBy } : {}),
        ...(page ? { p: page } : {})
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetConferencesData(conference_id: number = 0, year: number = 0, paging?: number) {
  try {
    const response = await axiosInstance.get('/conference-papers', {
      params: {
        ...(conference_id ? { conference_id: conference_id } : {}),
        ...(year ? { year: year } : {}),
        ...(paging ? { p: paging } : {}),
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetConfExplorerData() {
  try {
    const response = await axiosInstance.get('/conference-explorer');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetCollectionsData() {
  try {
    const response = await axiosInstance.get('/collections');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function GetCollectionPaperData(collection_id?: number,) {
  try {
    const response = await axiosInstance.get('/collection-papers', {
      params: {
        ...(collection_id ? { collection_id: collection_id } : {}),
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetSettingsDataForUser(admin: boolean = true) {
  try {
    const response = await axiosInstance.get('/settings' + admin ? '/admin/' : '/');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function GetCollectionsSimilarPapers(collectionIds: number[] = [], paging?: number) {
  try {
    const response = await axiosInstance.post('/get_collections_similar_papers/',
      {
        collectionIds: collectionIds,
        p: paging ?? 0,
      }
    );
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetSimilarPapers(paper_id: number = 0,) {
  try {
    const response = await axiosInstance.get('/get_similar_papers?', {
      params: {
        ...(paper_id ? { paper_id: paper_id } : {}),
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function Logout() {
  try {
    const response = await axiosInstance.get('/logout');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};

  }
}

export async function Login(key: string) {
  try {
    const response = await axiosInstance.get('/login/' + key + '/');
    return await response.data;
  }
  catch (err) {
    console.log('Login error');
    console.log(err);
    return {};
  }
}

// rating = [-1, 0, 1] from thumbs down/neutral/thumbs up
export async function MakePaperRating(rating: number, id: string) {
  try {
    const response = await axiosInstance.post('/make_rating/',
      {
        rating: rating,
        id: id,
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error making paper rating');
    console.log(err);
    return {};
  }
}

export async function GetAllUserCollections() {
  try {
    const response = await axiosInstance.get('/get_all_user_collections');
    return await response.data;
  }
  catch (err) {
    console.log('Error during all user collection retrieval');
    console.log(err);
    return {};
  }
}


export async function GetUserExpandedCollections() {
  try {
    const response = await axiosInstance.get('/get_expanded_collections');
    return await response.data;
  }
  catch (err) {
    console.log('Error during user expanded collections list retrieval');
    console.log(err);
    return {};
  }
}



export async function AddPaperCollection(collection: string, paper_id: string) {
  try {
    const response = await axiosInstance.post('/add_user_collection/',
      {
        collection: collection,
        paper_id: paper_id,
      });
    UpdateCollectionTab("add_collection", collection, paper_id, response.data.is_new_collection);
    return await response.data;
  }
  catch (err) {
    console.log('Error adding collection');
    console.log(err);
    return {};
  }
}

export async function RemovePaperCollection(collection: string, paper_id: string) {
  try {
    const response = await axiosInstance.post('/remove_user_collection/',
      {
        collection: collection,
        paper_id: paper_id,
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error removing collection');
    console.log(err);
    return {};
  }
}

export async function ExpandCollectionTab(collection_id: number) {
  try {
    const response = await axiosInstance.post('/expand_collection_tab/',
      {
        collection_id: collection_id,
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error expanding collection');
    console.log(err);
    return {};
  }
}

export async function CollapseCollectionTab(collection_id: number) {
  try {
    const response = await axiosInstance.post('/collapse_collection_tab/',
      {
        collection_id: collection_id,
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error collapsing collection');
    console.log(err);
    return {};
  }
}

export async function RenameUserCollection(collection_id: number, new_collection_name: string) {
  try {
    const response = await axiosInstance.post('/rename_user_collection/',
      {
        collection_id: collection_id,
        new_collection_name: new_collection_name,
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error on renaming collection');
    console.log(err);
    return {};
  }
}

export async function DeleteUserCollection(collection_id: number) {
  try {
    const response = await axiosInstance.post('/delete_user_collection/',
      {
        collection_id: collection_id,
      });
    return await response.data;
  }
  catch (err) {
    console.log('Error on deleting collection');
    console.log(err);
    return {};
  }
}

export async function MarkPaperAsRead(id: string) {
  try {
    const response = await axiosInstance.get('/mark_as_read/' + id);
    return await response.data;
  }
  catch (err) {
    console.log('Error marking paper as read');
    console.log(err);
    return {};
  }
}

export async function MarkPaperAsPinnedForPlanner(pinned: boolean, id: string) {
  try {
    const response = await axiosInstance.post('/pin_paper_for_planner', {
      pinned: pinned,
      id: id,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SubmitQuestionnaire(questionnaire_id: number, answers: Answer[]) {
  try {
    const response = await axiosInstance.post('/submit-questionnaire', {
      questionnaire_id: questionnaire_id,
      answers: answers
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetQuestionnaireData() {
  try {
    const response = await axiosInstance.get('/get-questionnaire-data', );
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetPaperPinnedStatusForPlanner(paper_id: string) {
  try {
    const response = await axiosInstance.get('/get_paper_pinned_for_planner_status', {
      params: {
        paper_id: paper_id,
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function MarkPaperAsBookmarked(bookmarked: boolean, id: string) {
  try {
    const response = await axiosInstance.post('/bookmark_paper/', {
      bookmarked: bookmarked,
      id: id,
    });
    return await response.data;
  }
  catch (err) {
    console.log('Error marking paper as bookmarked');
    console.log(err);
    return {};
  }
}

export async function MarkPosterAsPinned(pinned: boolean, poster_id: string) {
  try {
    const response = await axiosInstance.post('/pin_poster', {
      pinned: pinned,
      id: poster_id,
    });
    return await response.data;
  }
  catch (err) {
    console.log('Error during pinning poster');
    console.log(err);
    return {};
  }
}

export async function GetTutorials() {
  try {
    const response = await axiosInstance.get('/tutorials');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}