import { Box, Button, FormControl, FormControlLabel, Input, InputLabel, Snackbar, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
  GetOnboardingSettingsData,
  UpdateIterativeTrainingParameters,
} from '../../services/SettingsService';
import BoxSettingsContent from '../../components/box/BoxSettingsContent';

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function OnboardingSettingsPage() {
  const [iterativeTrainingSnackbarOpen, setIterativeTrainingSnackbarOpen] = useState(false);
  // iter training params
  const [boundaryBucketMin, setBoundaryBucketMin] = useState(0);
  const [boundaryBucketMax, setBoundaryBucketMax] = useState(0);
  const [boundaryBucketNumBins, setBoundaryBucketNumBins] = useState(0);

  const handleIterativeTrainingSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIterativeTrainingSnackbarOpen(false);
  };

  const updateIterativeTraining = () => {
    const updateRequest = UpdateIterativeTrainingParameters(boundaryBucketMin, boundaryBucketMax, boundaryBucketNumBins);
    updateRequest.then(res => {
      setIterativeTrainingSnackbarOpen(true);
    });
  };

  const handleSettingsDataUpdate = (res) => {
    setBoundaryBucketMin(res.boundary_bucket_min);
    setBoundaryBucketMax(res.boundary_bucket_max);
    setBoundaryBucketNumBins(res.boundary_bucket_num_bins);
  };

  useEffect(() => {
    const settingsData = GetOnboardingSettingsData();
    settingsData.then(res => {
      handleSettingsDataUpdate(res);
    });
  }, []);

  return (
    <>
      <Box>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={iterativeTrainingSnackbarOpen} autoHideDuration={3000} onClose={handleIterativeTrainingSnackbarClose} sx={{ position: 'fixed' }}>
          <Alert onClose={handleIterativeTrainingSnackbarClose} severity="info" icon={false} sx={{ width: '100%' }}>
            Iterative Training Parameters Updated!
          </Alert>
        </Snackbar>
      </Box>

      <BoxSettingsContent>
        <Box
          sx={{
            '& > :not(style)': { m: 1 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h6'>
            Iterative Training
          </Typography>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="boundaryBucketMin">Boundary Bucket Min:</InputLabel>
            <Input
              id="boundaryBucketMin"
              defaultValue={-0.5}
              value={boundaryBucketMin}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBoundaryBucketMin(Number(event.target.value));
              }}
              color="secondary"
              type="number"
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="boundaryBucketMax">Boundary Bucket Max:</InputLabel>
            <Input
              id="boundaryBucketMax"
              defaultValue={0.5}
              value={boundaryBucketMax}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBoundaryBucketMax(Number(event.target.value));
              }}
              color="secondary"
              type="number"
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="boundaryBucketBinSize">Boundary Bucket Number of Bins:</InputLabel>
            <Input
              id="boundaryBucketBinSize"
              defaultValue={0.1}
              value={boundaryBucketNumBins}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBoundaryBucketNumBins(Number(event.target.value));
              }}
              color="secondary"
              type="number"
            />
          </FormControl>
          <Button color='secondary' variant='outlined' sx={{ ml: '3px', mr: '3px', width: '300px' }} onClick={updateIterativeTraining}>Update Iterative Training Params</Button>
        </Box>
      </BoxSettingsContent>
    </>
  )
}
