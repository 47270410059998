import { Box, Typography, styled } from "@mui/material";
import { colourListToColour, relevanceToColour, roundRelevance } from "../../utils/relevanceUtils";
import { useEffect, useState } from "react";
import { GetPaperColourFromRelevance } from "../../services/DigestService";

interface VotePreviewProps {
  title: string;
  relevance: number;
  color: number[];
}

const SmallRelevanceBadge = styled('div')(({ theme }) => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  margin: '8px',
}));


export default function VotePreview(props: VotePreviewProps) {
  const [colour, setColour] = useState<string>('rgba(255,86,86,0.1)');

  useEffect(() => {
      setColour(colourListToColour(props.color));
  }, [props.relevance]);

  return (
    <Box sx={{
      backgroundColor: colour,
      borderRadius: '7px',
      display: 'flex',
      my: '2px',
      width: '100%',
    }}>
      <SmallRelevanceBadge>
        <Box sx={{ p: '8px',fontSize: '12px' }}>
          {roundRelevance(props.relevance)}
        </Box>
      </SmallRelevanceBadge>
      <Typography variant="body2">
        {props.title}
      </Typography>
    </Box>
  );
}