import Box from '@mui/material/Box';
import { Answer, Question } from '../Questionnaire';
import { Slider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import BreakpointUtils from '../../../utils/BreakpointUtils';


export default function IntegerSliderInput(props: { question: Question, setAnswer: (answer: Answer) => void, savedAnswer: Answer }) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const [value, setValue] = useState<number>(0);

  const handleValueChange = (event: Event, newValue: number) => {
    setValue(newValue);
    props.setAnswer({
      question_id: props.question.question_id,
      question_type: props.question.question_type,
      customText: String(newValue)
    });
  }

  useEffect(() => {
    setValue(Number(props.savedAnswer?.customText) || 0);
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        marginTop: onMobile ? '10px' : 0
      }}
    >
      {/* Question text at the top */}
      <Typography
        variant='subtitle1'
        sx={{
          fontSize: '1.1rem',
          lineHeight: '1.2',
          marginBottom: 'auto',
          // textAlign: 'center' 
        }}
      >
        {props.question.question_text}
      </Typography>

      {/* Centered slider and percentage */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1
        }}
      >
        <Typography
          variant='subtitle2'
          sx={{ fontSize: '1rem', lineHeight: '1', marginBottom: '8px' }}
        >
          {value}%
        </Typography>
        <Slider
          value={value}
          onChange={handleValueChange}
          aria-label="Default"
          valueLabelDisplay="auto"
          color="secondary"
          step={10}
          sx={{ maxWidth: onMobile? '80%': '65%' }}
        />
      </Box>
    </Box>
  );
}