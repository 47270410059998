import Rating, { IconContainerProps } from '@mui/material/Rating';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Answer, Question } from '../Questionnaire';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Divider, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import BreakpointUtils from '../../../utils/BreakpointUtils';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));


const customIcons: {
  [index: string]: {
    icon: React.ReactElement<unknown>;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Unhappy',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Displeased',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Happy',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Thrilled',
  },
};

function IconContainer(props: IconContainerProps & { onMobile: boolean }) {
  const { value, onMobile, ...other } = props;
  return <span {...other} style={{ padding: onMobile? '10px' : '20px', scale: onMobile? '2' : '2.8' }}>{customIcons[value].icon}</span>;
}

const getIconContainer = (onMobile: boolean) => {
  return (props: IconContainerProps) => <IconContainer {...props} onMobile={onMobile} />;
};


export default function RatingQuestion(props: { question: Question, setAnswer: (answer: Answer) => void, savedAnswer: Answer, handleNext: () => void }) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const [value, setValue] = useState<number | null>(null);

  const handleRatingChange = (newValue) => {
    setValue(newValue);
    props.setAnswer({
      question_id: props.question.question_id,
      question_type: props.question.question_type,
      rating: newValue
    });
    props.handleNext();
  }

  useEffect(() => {
    setValue(props.savedAnswer?.rating || null);
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', pt: '15px', width: '100%'}}>
      <Typography variant='subtitle1' sx={{ fontSize: onMobile? '0.9rem' : '1.1rem', lineHeight: '1.2' }}>
        {props.question.question_text}
      </Typography>
      <Box sx={{ display: 'flex', mt: 1 }}>
        <StyledRating
          value={value}
          precision={1}
          size='large'
          IconContainerComponent={getIconContainer(onMobile)}
          onChange={(event, newValue) => {
            handleRatingChange(newValue);
          }}
          highlightSelectedOnly
        />
      </Box>

    </Box>
  );
}