import axiosInstance from "./axiosInstance";

export async function GetInvitesData(key: string) {
  try {
    const response = await axiosInstance.get('/onboarding/invites/' + key);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetLandingPageInfo() {
  try {
    const response = await axiosInstance.get('/landing-data/');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function RegisterSignUpInterest(email: string) {
  try {
    const response = await axiosInstance.post('/waitlist-add-email/', {
      email: email
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function MarkLinkAsUsed(invitation_sha_key: string) {
  try {
    const response = await axiosInstance.post('/onboarding/invites/mark_link_as_used/', {
      used_key: invitation_sha_key
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SendRegistrationDetails(invite_key: string, name: string, surname: string, email: string,
  confirm_email: string, affiliation: string, role: string, study_field: string, use: string, password: string, website?: string) {
  try {
    const response = await axiosInstance.post('/onboarding/submit_registration_form', {
      invitation_sha_key: invite_key,
      name: name,
      surname: surname,
      email: email,
      confirm_email: confirm_email,
      affiliation: affiliation,
      role: role,
      study_field: study_field,
      use: use,
      password: password,
      website: website,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function ComfirmEmail(invitation_sha_key: string, entered_validation_code: string) {
  try {
    const response = await axiosInstance.post('/onboarding/registration/confirm_email', {
      invitation_sha_key: invitation_sha_key,
      entered_validation_code: entered_validation_code
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function ResendEmail(invitation_sha_key: string) {
  try {
    const response = await axiosInstance.post('/onboarding/registration/send_new_code/' + invitation_sha_key);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function CheckEmailAvailable(email: string) {
  try {
    const response = await axiosInstance.post('/check_email_available', {
      email: email,
    });
    return await [response.data, null];
  }
  catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function GetIterativeTrainingData(doRecreateCache?: boolean) {
  try {
    const response = await axiosInstance.get('/onboarding/iterative_training' + (doRecreateCache ? '?recreate_cache=true': ''));  
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function HideIterativeTrainingInstructions() {
  try {
    const response = await axiosInstance.get('/onboarding/iterative_training/hide_instructions');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function RegisterIterativeTrainingVote(paper_id: number, rating: string) {
  try {
    const response = await axiosInstance.post('/onboarding/iterative_training/register_vote', {
      paper_id: paper_id,
      rating: rating,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function FinishOnboardingData() {
  try {
    const response = await axiosInstance.get('/onboarding/finished');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function HideBaseAuthorsInstructions() {
  try {
    const response = await axiosInstance.get('/onboarding/bootstrapping/hide_login_instructions');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetBaseAuthorsData() {
  try {
    const response = await axiosInstance.get('/onboarding/bootstrapping/');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function SearchBaseAuthors(search_string: string) {
  try {
    const response = await axiosInstance.post('/onboarding/get_author_search_results', {
      search_string: search_string
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetAddedAuthors() {
  try {
    const response = await axiosInstance.get('/onboarding/get_added_authors');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function AddBaseAuthor(author_id: number) {
  try {
    const response = await axiosInstance.post('/onboarding/bootstrapping/add_author', {
      author_id: author_id
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function BaseAuthorsComplete() {
  try {
    const response = await axiosInstance.post('/onboarding/bootstrapping/continue');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function RemovePaper(paper_id: number) {
  try {
    const response = await axiosInstance.post('/onboarding/bootstrapping/prune_papers', {
      paper_ids: [paper_id]
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function RemoveAuthor(author_id: number) {
  try {
    const response = await axiosInstance.post('/onboarding/bootstrapping/delete_author', {
      author_id: author_id
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function PrunePapers(paper_ids: number[]) {
  try {
    const response = await axiosInstance.post('/onboarding/bootstrapping/prune_papers', {
      paper_ids: paper_ids
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function AddPapers(paper_ids: number[]) {
  try {
    const response = await axiosInstance.post('/onboarding/bootstrapping/add_papers', {
      paper_ids: paper_ids
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SetThreshold(threshold: number) {
  try {
    const response = await axiosInstance.post('/onboarding/bootstrapping/set_threshold/', {
      threshold: threshold
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetRegistrationInfo(invitation_sha_key: string) {
  try {
    const response = await axiosInstance.post('/onboarding/check_registration_info', {
      invite_sha_key: invitation_sha_key
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GenerateInviteForPage(invitation_page_key: string) {
  try {
    const response = await axiosInstance.post('/generate_invite_key', {
      invitation_page_key: invitation_page_key
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SendRestoreLinkEmail(email: string) {
  try {
    const response = await axiosInstance.post('/send_password_reset_email', {
      email: email
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SendLoginData(email: string, password: string) {
  try {
    const response = await axiosInstance.post('/password_login', {
      email: email,
      password: password
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SendResetPassword(password: string, sha_key: string) {
  try {
    const response = await axiosInstance.post('/reset_password/' + sha_key, {
      password: password,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}