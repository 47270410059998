import { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import { Box, TextField } from "@mui/material";
import { Answer, Question } from "../Questionnaire";
import BreakpointUtils from "../../../utils/BreakpointUtils";


export default function SingleText(props: { question: Question, setAnswer: (answer: Answer) => void, savedAnswer: Answer }) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const [customText, setCustomText] = useState<string>("");

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomText(event.target.value);
    props.setAnswer({
      question_id: props.question.question_id,
      question_type: props.question.question_type,
      customText: event.target.value,
    });
  };

  useEffect(() => {
    setCustomText(props.savedAnswer?.customText);
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
      <Typography variant='subtitle1' sx={{ fontSize: '1.1rem', lineHeight: '1.2', pb: 1, pt: onMobile? 0 : 1 }}>
        {props.question.question_text}
      </Typography>

      {/* Multiline text area */}
      <TextField
        value={customText}
        onChange={handleTextChange}
        label="Your Feedback"
        variant="outlined"
        multiline
        rows={onMobile? 4.5 : 5.5}
        color="secondary"
        fullWidth
      />

    </Box>
  );
};
