import { useState } from "react";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, IconButton, Link, Paper, styled } from "@mui/material";
import { Close } from '@mui/icons-material';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MultipleChoice from "./question/MultipleChoice";
import SingleText from "./question/SingleText";
import RatingQuestion from "./question/RatingQuestion";
import { SubmitQuestionnaire } from "../../services/DigestService";

export interface Question {
  question_id: number;
  question_type: 'MULTIPLE_CHOICE' | 'RATING' | 'SINGLE_TEXT';
  question_text: string;
  question_choices?: string[];
  has_custom_text_field?: boolean;
}

export interface Answer {
  question_id: number;
  question_type: 'MULTIPLE_CHOICE' | 'RATING' | 'SINGLE_TEXT';
  rating?: number;
  answers?: string[];
  customText?: string;
}

interface QuestionnaireProps {
  questionnaire: Questionnaire;
  closeQuestionnaire: (submitted?: boolean) => void;
}

export interface Questionnaire {
  questionnaire_id: number;
  questions: Question[];
}

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import BreakpointUtils from "../../utils/BreakpointUtils";
import IntegerSliderInput from "./question/IntegerSliderInput";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}));

export default function SIQuestionnaire(props: QuestionnaireProps) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<Answer[]>(
    props.questionnaire.questions?.map(() => null) || []
  );

  const handleNext = () => {
    if (activeQuestion < props.questionnaire.questions.length - 1) {
      setActiveQuestion((prevActiveStep) => prevActiveStep + 1);
    } else {
      submitQuestionnaire(true);
    }
  };

  const handleBack = () => {
    setActiveQuestion((prevActiveStep) => prevActiveStep - 1);
  };

  const submitQuestionnaire = async (submitted: boolean) => {
    await SubmitQuestionnaire(props.questionnaire.questionnaire_id, answers);
    console.log('sent');
  
    // Use a small delay before unmounting to prevent React processing errors
    setTimeout(() => {
      props.closeQuestionnaire(submitted);
    }, 50);
  };

  const setAnswer = (answer: Answer) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[activeQuestion] = answer;
      return updatedAnswers;
    });
  }

  const questionContent = (questionType: string) => {
    switch (questionType) {
      case 'MULTIPLE_CHOICE':
        return <MultipleChoice key={props.questionnaire.questions[activeQuestion].question_id} setAnswer={setAnswer} question={props.questionnaire.questions[activeQuestion]} savedAnswer={answers[activeQuestion]} />;
      case 'RATING':
        return <RatingQuestion key={props.questionnaire.questions[activeQuestion].question_id} setAnswer={setAnswer} question={props.questionnaire.questions[activeQuestion]} savedAnswer={answers[activeQuestion]} handleNext={handleNext} />;
      case 'SINGLE_TEXT':
        return <SingleText key={props.questionnaire.questions[activeQuestion].question_id} setAnswer={setAnswer} question={props.questionnaire.questions[activeQuestion]} savedAnswer={answers[activeQuestion]} />;
      case 'INTEGER_INPUT':
        return <IntegerSliderInput key={props.questionnaire.questions[activeQuestion].question_id} setAnswer={setAnswer} question={props.questionnaire.questions[activeQuestion]} savedAnswer={answers[activeQuestion]}/>;
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        width: onMobile? '90%' : 460,
        zIndex: 1000,
      }}
    >
      <Paper elevation={5} sx={{
        backgroundColor: '#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: onMobile? 340 : 330,
        boxShadow: "rgba(0, 0, 0, 0.4) 0px 6px 10px -2px, rgba(0, 0, 0, 0.28) 0px 10px 16px 0px, rgba(0, 0, 0, 0.24) 0px 2px 28px 0px",
      }}>

        {/* Header */}
        <Typography variant='h4' sx={{
          my: 1,
          display: 'flex',
          justifyContent: 'center',
          fontSize: '1.6rem',
        }}>
          Help us Improve!
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

          {/* <Typography variant='h5' sx={{ py: 0.5, fontSize: '1.4rem' }}>Scholar Inbox Survey</Typography> */}

          <IconButton
            aria-label="close"
            onClick={() => submitQuestionnaire(false)}
            color="secondary"
            sx={{ position: 'absolute', top: 8, right: 8, scale: '1' }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>

        {/* main content: question + progress */}
        <Box sx={{ borderRadius: 2, mx: 1.4, backgroundColor: 'white', p: 1, height: onMobile? '270px' : '250px' }}>

          {/* questions */}
          <Box sx={{ height: '190px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {questionContent(props.questionnaire.questions[activeQuestion].question_type)}
          </Box>

          {/* stepper buttons */}
          <Box sx={{ mt: onMobile? '30px' : '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button
              color="secondary"
              sx={{
                pl: 0,
                fontSize: '1rem',
                scale: '0.9',
              }}
              size="small"
              variant="outlined"
              onClick={handleBack}
              disabled={activeQuestion === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <Box sx={{ width: onMobile? '0' : '210px' }}>
                <LinearProgress
                  color="secondary"
                  variant="determinate"
                  value={((activeQuestion + 1) * 100) / props.questionnaire.questions.length}
                  sx={{
                    height: '10px',
                    borderRadius: '4px'
                  }}
                />
              </Box>

              <Typography
                variant="body2"
                color="secondary"
                sx={{
                  ml: 1,
                  pointerEvents: 'none',
                }}
              >
                {`${activeQuestion + 1}/${props.questionnaire.questions.length}`}
              </Typography>
            </Box>

            <Button
              color="secondary"
              sx={{
                pr: activeQuestion === props.questionnaire.questions.length - 1 ? '8px' : 0,
                fontSize: '1rem',
                scale: '0.9',
              }}
              size="small"
              variant="outlined"
              onClick={handleNext}
            >
              {activeQuestion === props.questionnaire.questions.length - 1 ? 'Submit' : 'Next'}
              {activeQuestion === props.questionnaire.questions.length - 1 ? '' : <KeyboardArrowRight />}
            </Button>
          </Box>
        </Box>

        {/* Footer: Privacy Policy and Terms of Service */}
        <Typography variant='body2' color={'grey'}
          sx={{ mt: 'auto', fontSize: onMobile? '0.6rem' : '0.875rem', display: 'flex', justifyContent: 'center', mb: '3px' }}
        >
          Your feedback is subject to our
          <Link color="inherit" href={"/privacy-policy"} marginLeft={0.5} marginRight={0.5}>
            Privacy Policy
          </Link>
          and
          <Link color="inherit" href={"/terms-of-service"} marginLeft={0.5} marginRight={0.5}>
            Terms of Service
          </Link>
        </Typography>

      </Paper>
    </Box>
  );
};
